import { type ChangeEvent } from "react";
import { Dropdown, Card, CardSection, InputCheckbox, VStack } from "therese";

import { usePreferences, usePreferencesMutation } from "../usePreferences";
import { agentPhaseFilter } from "../../utils/phaseText";
import { agentRolesFilter } from "../../utils/roleText";

export const AgentPhaseFilter = () => {
  const { data: preferences } = usePreferences();
  const { mutate } = usePreferencesMutation();

  const phases = preferences?.phase || [];
  const roles = preferences?.roles || [];

  function updateFilteredValues(
    currentPreferences: string[],
    changedValue: string
  ) {
    const newValues = [...currentPreferences];

    if (!currentPreferences.includes(changedValue)) {
      newValues.push(changedValue);
    } else {
      const idx = newValues.indexOf(changedValue);
      newValues.splice(idx, 1);
    }

    return newValues;
  }

  function handleFilterChange(
    event: ChangeEvent<HTMLInputElement>,
    filterType: string
  ) {
    event.stopPropagation();

    let newPhases = [...phases];
    let newRoles = [...roles];

    if (filterType === "phases") {
      newPhases = updateFilteredValues(newPhases, event.target.value);
      mutate({ ...preferences, phase: newPhases });
    } else if (filterType === "roles") {
      newRoles = updateFilteredValues(newRoles, event.target.value);
      mutate({ ...preferences, roles: newRoles });
    }
  }

  return (
    <Dropdown title="Filtrer">
      <Card as="form">
        <CardSection title="Fase">
          <VStack spacing="xxs">
            {agentPhaseFilter.items.map((item) => {
              const checked = phases.includes(item.value);
              return (
                <InputCheckbox
                  key={item.label}
                  checked={checked}
                  {...item}
                  onChange={(event) => handleFilterChange(event, "phases")}
                />
              );
            })}
          </VStack>
        </CardSection>
        <CardSection title="Rolle">
          <VStack spacing="xxs">
            {agentRolesFilter.items.map((item) => {
              const checked = roles.includes(item.value);
              return (
                <InputCheckbox
                  key={item.label}
                  checked={checked}
                  {...item}
                  onChange={(event) => handleFilterChange(event, "roles")}
                />
              );
            })}
          </VStack>
        </CardSection>
      </Card>
    </Dropdown>
  );
};
