import {
  useRef,
  useState,
  useEffect,
  useCallback,
  Suspense,
  useLayoutEffect,
} from "react";
import { InputText, Button, useMediaQuery, Link } from "therese";
import cx from "classnames";

import { useExpandWithContent, useVisualViewportChange } from "./hooks";
import { ReactComponent as ArrowUp } from "symbols/arrow_up.svg";
import { ReactComponent as Plus } from "symbols/plus.svg";

import * as styles from "./styles.module.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useProfile } from "../../../../components/useProfile";
import FavoriteTemplates from "../TemplateModal/FavoriteTemplates";
import { useTemplates } from "../TemplateModal/hooks/useTemplates";
import { favorites } from "../TemplateModal/hooks/types";
import { TemplateModal } from "../TemplateModal/TemplateModal";
import { usePortalQuery } from "../../../../components/usePortalQuery";

interface IFormProps {
  onChange: () => void;
  onSubmit: (content: string) => void;
  onSubmitDraft: (content: string) => void;
  deleteDraft: () => void;
  disabled: boolean;
  context?: string;
}

type DraftResponse = {
  data: {
    draft: string;
  };
};

const Form: React.FC<IFormProps> = ({
  onChange,
  onSubmit,
  onSubmitDraft,
  deleteDraft,
  disabled,
  context,
}) => {
  const form = useRef<HTMLFormElement>(null);
  const textarea = useRef<HTMLTextAreaElement>(null);
  const smallScreen = useMediaQuery(`(max-width: ${styles.medium})`);
  const [writing, setWriting] = useState(false);
  const [draft, setDraft] = useState("");
  const navigate = useNavigate();
  const { data: user } = useProfile();

  const { data } = usePortalQuery(
    `conversation/${context}/draft`
  ) as DraftResponse;

  const { isLoading, categories } = useTemplates();

  const templatesUrl = `maler/${
    categories[0]?.displaySlug || favorites.displaySlug
  }`;

  const { readjust } = useExpandWithContent(textarea);
  useVisualViewportChange();

  const handleSubmit = useCallback(() => {
    if (form.current) {
      const message = new FormData(form.current).get("message");
      if (message !== "" && typeof message === "string") {
        onSubmit(message);
        setWriting(false);
        form.current.reset();
        setDraft("");
      }
    }
  }, [onSubmit]);

  const onUseMessageTemplate = useCallback(
    (content: string) => {
      if (textarea.current) {
        textarea.current.value = content;
        setDraft(content);
      }
      readjust();
    },
    [textarea]
  );

  useEffect(() => {
    if (data && data.draft) {
      setDraft(data.draft);
    }
  }, [data]);

  useEffect(() => {
    const saveDraft = setTimeout(() => {
      if (form.current) {
        const message = new FormData(form.current).get("message");
        if (message !== "" && typeof message === "string") {
          onSubmitDraft(message);
        } else if (message === "" && draft === "") {
          // There was a draft, but the user cleared the message box
          deleteDraft();
        }
      }
    }, 1000);
    return () => clearTimeout(saveDraft);
  }, [draft]);

  const isMobile = (userAgent: string) => {
    for (const deviceName of ["Android", "iPhone", "iPad"]) {
      // There aren't any really good alternatives for checking a given device is a mobile or not
      // All options have an inherit downside
      // user agents cen be faked, syntax can change etc
      // Checking for touch support would return true for desktop/laptop monitors
      // Checking for screen size is also not a good idea, as it's not the size of the monitor
      // that we're interested in, but rather if the user is using an on screen touch keyboard
      // (meaning the "enter" button shouldn't send a message, but rather cause a line break)
      //
      // The solution used here is a "good enough" solution that should hopefully catch most of
      // the devices we're interested in supporting
      if (userAgent.includes(deviceName)) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    const formElm = form.current;
    function handleKeyDown(event: KeyboardEvent): void {
      if (isMobile(navigator.userAgent)) {
        if (event.key !== "Enter" || !event.metaKey) {
          return;
        } else {
          event.preventDefault(); // Prevents "Enter" from sending line break character after submit
          handleSubmit();
        }
      } else {
        const isLinebreak = event.shiftKey && event.key === "Enter";
        if (event.key !== "Enter" || isLinebreak) {
          return;
        } else {
          event.preventDefault(); // Prevents "Enter" from sending line break character after submit
          handleSubmit();
        }
      }
    }

    if (formElm) {
      formElm.addEventListener("keydown", handleKeyDown);
      return (): void => {
        formElm.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [handleSubmit]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (writing) {
      onChange();
      interval = setInterval(() => {
        onChange();
      }, 2000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [writing, onChange]);

  useLayoutEffect(() => {
    const checkFormPosition: () => void = () => {
      const rect = form.current?.getBoundingClientRect();
      if (rect) {
        const windowHeight =
          window.innerHeight || document.documentElement.clientHeight;

        const isInViewport = rect.bottom - 16 <= windowHeight;
        if (form.current) {
          form.current.style.paddingBottom = isInViewport
            ? "0"
            : rect.bottom - windowHeight + 16 + "px";
        }
      }
    };

    checkFormPosition();

    window.addEventListener("resize", checkFormPosition);
    return () => {
      window.removeEventListener("resize", checkFormPosition);
    };
  }, [disabled]);

  return !disabled ? (
    <>
      {user?.type === "agent" ? (
        <FavoriteTemplates onUseTemplate={onUseMessageTemplate} />
      ) : null}
      <div className={styles.formContainer}>
        <form
          className={styles.form}
          ref={form}
          onReset={() => {
            if (textarea.current) {
              textarea.current.style.height = "inherit";
            }
          }}
          onSubmit={(event) => {
            event.preventDefault();
            handleSubmit();
          }}
        >
          <label className="visuallyhidden" htmlFor="message">
            Melding
          </label>
          <InputText
            className={cx(styles.input, {
              [styles.paddingRight]: user?.type === "agent", // space for message template button
            })}
            ref={textarea}
            type="textarea"
            id="message"
            name="message"
            placeholder="Skriv her..."
            rows="1"
            value={draft}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
              setWriting(event.target.value !== "");
              setDraft(event.target.value);
            }}
          />
          {user?.type === "agent" ? (
            <div className={styles.messageTemplatesButtonContainer}>
              {!isLoading && (
                <button
                  className={styles.messageTemplatesButton}
                  aria-label="Maler"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(templatesUrl);
                  }}
                >
                  <Plus />
                </button>
              )}
            </div>
          ) : null}
          {smallScreen ? (
            <Link
              className={styles.smallButton}
              as="button"
              type="submit"
              icon={ArrowUp}
              hideChildren
            >
              Send
            </Link>
          ) : (
            <Button
              className={styles.button}
              theme="action"
              type="submit"
              size="large"
            >
              Send
              <ArrowUp aria-hidden />
            </Button>
          )}
          {user?.type === "agent" ? (
            <Suspense fallback={null}>
              <Routes>
                <Route
                  path="maler/:categoryId/*"
                  element={
                    <TemplateModal
                      isOpen
                      onUse={onUseMessageTemplate}
                      onDismiss={() => navigate("./")}
                      title="Tekstmaler"
                    />
                  }
                />
                <Route
                  path="maler/:categoryId/rediger/:templateId"
                  element={
                    <TemplateModal
                      isOpen
                      onDismiss={() => navigate("./")}
                      editing
                      onUse={onUseMessageTemplate}
                      title="Opprett egen tekstmal"
                    />
                  }
                />
                <Route
                  path="maler/:categoryId/opprett"
                  element={
                    <TemplateModal
                      isOpen
                      onDismiss={() => navigate("./")}
                      editing
                      onUse={onUseMessageTemplate}
                      title="Opprett egen tekstmal"
                    />
                  }
                />
              </Routes>
            </Suspense>
          ) : null}
        </form>
      </div>
    </>
  ) : null;
};

export default Form;
