import { get, set } from "idb-keyval";
import { useMutation, useQuery, useQueryClient } from "react-query";

const KEY = "agent-preferences";
const DEFAULT_VALUES: IPreferences = {
  view: "default",
  phase: [],
  roles: [],
  offset: 0,
  filter: "",
};

interface IPreferences {
  view: string;
  filter: string;
  phase: string[];
  roles: string[];
  offset: number;
}

function usePreferences() {
  return useQuery<IPreferences>(KEY, () =>
    get(KEY).then((resp) => resp || DEFAULT_VALUES)
  );
}

function usePreferencesMutation() {
  const client = useQueryClient();
  return useMutation(
    (values: Partial<IPreferences>) => {
      return set(
        KEY,
        updatePreferences(client.getQueryData(KEY) as IPreferences, values)
      );
    },
    {
      onMutate: (values) => {
        client.setQueryData<IPreferences>(KEY, (old) =>
          updatePreferences(old as IPreferences, values)
        );
      },
    }
  );
}

function updatePreferences(
  oldPrefs: IPreferences,
  newPrefs: Partial<IPreferences>
): IPreferences {
  let offset = newPrefs.offset;
  if (offset === undefined) {
    offset = newPrefs.offset || oldPrefs?.offset || 0;
  }
  if (newPrefs.phase !== undefined && oldPrefs?.phase !== newPrefs.phase) {
    offset = 0;
  }

  return { ...oldPrefs, ...newPrefs, offset };
}

export { usePreferences, usePreferencesMutation };
