const badges = [
  "",
  "Megler 1",
  "Megler 2",
  "Ansvarlig megler",
  "Saksbehandler",
];

const agentRolesFilter = {
  name: "role",
  type: "checkbox",
  items: [
    { label: badges[1], value: "1" },
    { label: badges[2], value: "2" },
    { label: badges[3], value: "3" },
    { label: badges[4], value: "4" },
  ],
};

export { badges as roleBadges, agentRolesFilter };
